import React, { useState, useEffect } from 'react';
import CoralChart from '../CoralChart/CoralChart';
import { Loader } from 'lucide-react';
import { useEcosystemData } from '../../hooks/useEcosystemData';

interface EcosystemVisualProps {
  width?: number;
  height?: number;
}

const EcosystemVisual: React.FC<EcosystemVisualProps> = ({ 
  width: propWidth = 300, 
  height: propHeight = 300 
}) => {
  const { data, isLoading, error } = useEcosystemData();
  const [width, setWidth] = useState(propWidth);
  const [height, setHeight] = useState(propHeight);

  // Add responsiveness to the ecosystem visual
  useEffect(() => {
    const handleResize = () => {
      // Adjust size based on screen width
      if (window.innerWidth < 640) { // Small mobile
        setWidth(220);
        setHeight(220);
      } else if (window.innerWidth < 768) { // Larger mobile
        setWidth(250);
        setHeight(250);
      } else if (window.innerWidth < 1024) { // Tablet
        setWidth(270);
        setHeight(270);
      } else { // Desktop
        setWidth(propWidth);
        setHeight(propHeight);
      }
    };

    // Initial setup
    handleResize();
    
    // Add event listener
    window.addEventListener('resize', handleResize);
    
    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, [propWidth, propHeight]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full min-h-[200px]">
        <Loader className="w-8 h-8 text-coral-pink animate-spin" />
      </div>
    );
  }

  if (error || !data || data.length === 0) {
    return (
      <div className="flex items-center justify-center w-full h-full min-h-[200px] text-gray-400 text-sm">
        Ecosystem data unavailable
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center w-full">
      <CoralChart
        width={width}
        height={height}
        data={data}
        showAmounts={false}
      />
    </div>
  );
};

export default EcosystemVisual;
